<script lang="ts" setup>
import { useDisplay } from "vuetify";
import { usePlaceStore } from "@/stores/place";

const { mobile } = useDisplay();
const placeStore = usePlaceStore();
const { place } = placeStore;
</script>
<template lang="pug">
v-footer(
  :app="mobile"
  v-if="mobile && place"
  class="elevation-3"
)
  v-row(justify="space-around")
    v-col(cols="auto" v-if="place.tel")
      v-btn(
        size="large"
        :href="`tel:${place.tel}`"
      )
        v-icon mdi-phone-in-talk
        b 電話する
    v-col(cols="auto" v-if="place.aff_url")
      v-btn(
        size="large"
        class="info-btn"
        color="warning"
        :href="place.aff_url"
      )
        v-icon mdi-information
        b 予約する
</template>

<style lang="scss" scoped>
.info-btn {
  color: white !important;
}
</style>
